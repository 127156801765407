import { Button } from 'antd';
import React, { useState, useEffect, useRef, useCallback, Component } from 'react';

import {
  setSessionFormId
} from '../../utils/localStorage.js';
import './product.css';

export default props => {

  // type 用于流程完成后，显示的什么内容的判断标识
  const productList = [
    { name1: '全科智能', name2: '病历助手', en_name: 'EMR', sceneId: '7b73f7bbf5604fa', type: '1' },
    { name1: '精神科', name2: '病历助手', en_name: 'EMR', sceneId: '76511069c0d14c8', type: '2' },
    { name1: '智能体检', name2: '助手', en_name: 'PRE-DIAGNOSIS', sceneId: '7c72afd3a75b4e7,', type: '3' }
  ]


  window.onReceiveRobotText = onReceiveRobotText;
  function onReceiveRobotText(text) {
    handleRobotText(text);
  }
  /**
  * text 
  */
  function handleRobotText(text) {
    if (text.includes('精神科') || text.includes('智慧医生')) {
      gotoCheck(productList[0]);
      return;
    }
    if (text.includes('全科') || text.includes('病历助手')) {
      gotoCheck(productList[1]);
      return;
    }
    if (text.includes('体检助手')) {
      gotoCheck(productList[2]);
      return;
    }
  }
  // 点击按钮，体验产品
  function gotoCheck(item) {
    console.log('sceneId:', item);
    console.log('sceneId:', item.sceneId);
    // setSessionFormId(sceneId);
    props.history.push({ pathname: '/Welcome', state: { formId: item.sceneId, type: item.type } });
  }

  return (
    <div>
      <div className='product-main'>
        {
          productList.map((item, index) => {
            return (
              <div key={index} className="product-content" onClick={() => {
                gotoCheck(item)
              }}>
                <div className="product-en-name">
                  <div className="product-en-name-title">{item.en_name}</div>
                </div>
                <div className="product-name">
                  <div>{item.name1}</div>
                  <div>{item.name2}</div>
                </div>
                <div className="product-button">点击查看</div>
              </div>
            )
          })
        }
      </div>
      {/* <div>
        <div className="go-back-button" onClick={() => {
          props.history.push({ pathname: '/' });
        }}>返回首页</div>
      </div> */}
    </div>
  );
}