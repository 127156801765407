import { Button } from 'antd';
import React, { useEffect, useState } from 'react';
import { Player } from 'video-react';
import health from '../../assets/images/health.png';
import note from '../../assets/images/note.png';
import pointer from '../../assets/images/pointer.png';
import robot from '../../assets/images/robot.png';
import Service_full_Copy from '../../assets/images/Service_full_Copy.png';
import './home.css';




export default props => {
  const [player, setPlayer] = useState(null);
  const assistantList = [{
    icon: Service_full_Copy,
    title: '精神科智能助手',
    content: '精神科疾病初筛、精神科写病历、精神科两边、辅助科研'
  }, {
    icon: robot,
    title: '急诊写病历助手',
    content: '精神科、 儿科 、儿保、急诊、大内科、普外、中医科、其他专科'
  }, {
    icon: health,
    title: '住院病房助手',
    content: '病房写病历、入院首次病程记录、患者安全预警、康复评估、辅助科研'
  }, {
    icon: note,
    title: '急诊诊前助手',
    content: '导诊、体检导检、诊前专病筛查、实体机器人、辅助科研'
  }];
  const introList = [
    '采用国际前沿人工智能技术，拥有多项自主知识产权',
    '可应用于门急诊诊前，诊中，病房，随访等多个场景',
    '核心产品智能医生助手， 已获得顶尖三甲医院的临床验证并采购',
    '公司入选2020人工智能新基建白皮书',
    '产品覆盖20多个科室',
    '上海市”临港杯“人工智能创业比赛决赛获奖企业'
  ];
  window.onReceiveRobotText = onReceiveRobotText;
  function onReceiveRobotText(text) {
    console.log('robot-text:', text)
    handleRobotText(text);
  }

  useEffect(() => {
    setTimeout(() => {
      console.log('player:1', player)
      if (player) {
        player.play();
      }
    }, 1000)
  }, [player])

  /** 
   * 
   * @param {语音文字} text 
   * 根据语音匹配特定逻辑场景
   * 1.视频控制播放：播放，暂停，静音，声音大一点，声音小一点
   * 2.体验产品---跳转体验页面
   * 3.介绍公司，机器人自动回复
   */
  function handleRobotText(text) {
    // 视频播放处理语音逻辑
    if (text.includes('播放')) {
      if (!text.includes('暂停')) {
        handleVideoOperation('1');
      } else {
        handleVideoOperation('2');
      }
    }
    if (text.includes('暂停')) {
      handleVideoOperation('2');
    }
    // 音量处理
    if (text.includes('声音')) {
      if (text.includes('大') && !text.includes('太大')) {
        handleVideoOperation('3');
      }
      if (text.includes('太大') || text.includes('小')) {
        handleVideoOperation('4');
      }
    }
    if (text.includes('静音')) {
      handleVideoOperation('5');
    }
    // 体验产品处理
    if (text.includes('体验') || text.includes('产品')) {
      experience()
    }
  }

  /**
   * 
   * @param {操作参数} flag 
   * 1.播放
   * 2.暂停
   * 3.vol+
   * 4.vol-
   * 5.mute
   */
  function handleVideoOperation(flag) {
    console.log('player:', player)
    console.log('flag:', flag)
    switch (flag) {
      case '1':
        player.play();
        break;
      case '2':
        player.pause();
        break;
      case '3':
        setPlayer({ volume: player.volume + 0.1 })
        break;
      case '4':
        setPlayer({ volume: player.volume - 0.1 })
        break;
      case '5':
        setPlayer({ muted: true });
        break;
      default:
        break;
    }

  }

  /**
   * 体验产品，跳转
   */
  function experience() {
    props.history.push({ pathname: '/product' });
  }

  // 单个助手div
  function everyAssistantDiv(index) {
    return <div style={{ flex: 1, marginBottom: 20, padding: '0 20px' }}>
      <div><img alt='' src={assistantList[index].icon} /></div>
      <div style={{ fontWeight: 'bold', margin: '20px auto', fontSize: 26 }}>{assistantList[index].title}</div>
      <div style={{ textAlign: 'left', fontSize: 22 }}>{assistantList[index].content}</div>
    </div>
  }

  // 助手div
  function assistantDiv() {
    return <div>
      <div style={{ display: 'flex' }}>
        {everyAssistantDiv(0)}
        {everyAssistantDiv(1)}
      </div>
      <div style={{ display: 'flex' }}>
        {everyAssistantDiv(2)}
        {everyAssistantDiv(3)}
      </div>
    </div>
  }

  // 介绍div
  function introDiv() {
    return <div>
      <div style={{ fontWeight: 'bold', margin: '20px auto', fontSize: 26 }}>Siuvo（舒辅）是一家专注于推动临床医疗信息化走向智能化的高科技企业</div>
      <div>
        {introList.map((intro, index) =>
          <div key={index} style={{ display: 'flex', margin: '15px auto' }}>
            <div><img alt='' src={pointer} /></div>
            <div style={{ flex: 1, fontSize: 22 }}>{intro}</div>
          </div>
        )}
      </div>
    </div>
  }

  return (
    <div className='home-content'>
      <div className='home-content-video-intro'>
        <Player width="100%" height={500}
          fluid={false} loop
          ref={player => {
            setPlayer(player)
          }}
          preload="auto">
          <source src={"https://video.siuvo.com.cn/company_introduction/video-introduction.mp4"} />
        </Player>
      </div>
      <div className='home-content-product-intro'>
        {assistantDiv()}
        <div className='btn-taste'>
          <Button style={{ height: 61, padding: '6.4px 50px', fontSize: 24, borderRadius: 100 }} type='primary' onClick={experience} size='large'>点击体验产品</Button>
        </div>
      </div>
      <div className='home-content-factory-intro'>
        {introDiv()}
        {/* <img src={imageAdress} alt='' />
        <p>Siuvo（舒辅）是一家专注于推动<span className='content-bold'>临床医疗信息化走向智能化</span>的高科技企业</p>
        <ul>
          <li>采用国际前沿人工智能技术，拥有多项自主知识产权</li>
          <li>核心产品智能医生助手， 已获得顶尖三甲医院的临床验证并采购</li>
          <li>产品覆盖20多个科室</li>
          <li>可应用于门急诊诊前，诊中，病房，随访等多个场景</li>
          <li>公司入选2020人工智能新基建白皮书</li>
          <li>上海市”临港杯“人工智能创业比赛决赛获奖企业</li>
        </ul> */}
      </div>
    </div>
  );
}