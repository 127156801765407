import React, { Component } from 'react';
import { Route, HashRouter } from 'react-router-dom';

import Header from './pages/header/header.js';
import Welcome from './pages/welcome/welcome.js';
import Slides from './pages/slides/slides.js';
import Home from './pages/home/home.js';
import Product from './pages/product/product.js';
import Swipes from './pages/swipes/swipes.js';
import 'antd/dist/antd.css';
import 'video-react/dist/video-react.css';

class App extends Component {
  render() {
    return (
      <>
        <Header></Header>
        <HashRouter>
          <Route exact path="/" component={Home} />
          <Route exact path="/product" component={Product} />
          <Route exact path="/Welcome" component={Welcome} />
          <Route exact path="/slides" component={Slides} />
          <Route exact path="/swipes" component={Swipes} />
        </HashRouter>
      </>
    );
  }
}

export default App;
