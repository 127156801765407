import React, { useState, useEffect, useRef, useCallback, Component } from 'react';


import { Button, Input } from 'antd';
// import './welcome.css';
import './welcome.styl';

import {
  verifyCodeReturnUsersOrRegister,
  queryMessage,
  getQrcodeSceneData,
  getServiceItem,
  startServiceItemWithCheckEncounter,
  getJsonFormById
} from '../../modules/shufu';
import { initShuaiCloud as initShuaiCloudFromClientInstance } from '../../modules/shuaicloud/clientInstance';
import { messageEventEmitter } from '../../modules/shuaicloud/index.js';
import ShufuFormChat from '../../modules/ShufuFormChat.js';
import {
  setCurrentUserId,
  setOrganizationId,
  setToken
} from '../../utils/localStorage.js';
import getParameterByName from '../../utils/getParameterByName';
import { getCurrentUserId, setServiceInstanceId, getServiceInstanceId, setServiceItemId } from '../../utils/localStorage.js';

import _ from 'lodash';
import moment from 'moment';

import IatRecorder from '../../utils/speechRecgUtil/IatRecorder';




export default function Welcome(props) {
  const [iatRecorder, setIatRecorder] = useState(null);
  const [dictationText, setDictationText] = useState('');
  const [recorderState, setRecorderState] = useState('closed');
  const [sceneId, setSceneId] = useState('');
  const [messageList, setMessageList] = useState([]);
  const [formId, setFormId] = useState('');
  const [schema, setSchema] = useState(null);
  const [startFlag, setStartFlag] = useState(false);
  const [type, setType] = useState('');
  const { location } = props;

  window.onReceiveRobotText = onReceiveRobotText;
  function onReceiveRobotText(text) {
    console.error(text);
    if (text.includes('开始')) startTest();
  }

  const startServiceInstanceWithSceneId = useCallback(async sceneId => {
    let sceneData = await getQrcodeSceneData(sceneId);
    sceneData = sceneData.sceneData;
    let tags = JSON.parse(sceneData.tags || '[]');
    tags.push(`tid=${window.__tid || null}`);
    tags.push(`rootTid=${window.__rootTid || null}`);
    let options = {
      referralCode: sceneData.rc || '',
      newServiceItemId: sceneData.sid || undefined,
      newServiceInstanceName: sceneData.sn || undefined,
      wfVariables: sceneData.wfVariables || '{}',
      startedWithUserId: sceneData.startedWithUserId || undefined,
      organizationId: sceneData.organizationId || undefined,
      tags: JSON.stringify(tags)
    };

    if (options.newServiceItemId) {
      setServiceItemId(options.newServiceItemId);
      let serviceItem = await getServiceItem(options.newServiceItemId);
      console.log('---------getServiceItem----------------')
      console.log(serviceItem)
      console.log(options)
      let {
        wfVariables
      } = getServiceitemInfo(serviceItem, options);

      let newServiceInstance = await startServiceItemWithCheckEncounter({
        serviceItemId: options.newServiceItemId,
        customerId: getCurrentUserId(),
        wfVariables: JSON.stringify(wfVariables),
        name: options.newServiceInstanceName || serviceItem.name,
        tags: JSON.parse(options.tags || '[]') || [],
        sceneId,
        tid: window.__tid || null
      });
      console.log('newServiceInstance', newServiceInstance);
      const serviceInstanceId = newServiceInstance.serviceInstance &&
        newServiceInstance.serviceInstance.id;
      setServiceInstanceId(serviceInstanceId);
      console.log('--------------get----serviceInstanceId-------------', serviceInstanceId)
      setServiceInstanceId(serviceInstanceId);
    }
  }, []);

  const tryStartNewServiceInstance = useCallback(async sceneId => {
    console.log('tryStartNewServiceInstance:', sceneId);
    if (sceneId) {
      await startServiceInstanceWithSceneId(sceneId);
    }
  }, [startServiceInstanceWithSceneId]);

  const onReceiveMessage = useCallback((fullMessage) => {
    console.log('onReceiveMessage', fullMessage);
    let messageJson = JSON.parse(fullMessage.message.data);
    let type = '';
    if (messageJson._lcattrs && messageJson._lcattrs.type === 'text') {
      type = 'text';
    } else if (
      messageJson._lcattrs &&
      messageJson._lcattrs.type === 'jsonSchema'
    ) {
      let _form = JSON.parse(messageJson._lctext || '{}');
      let partSchema = _form.schema || {};
      let shufuFormId = partSchema.shufuFormId;
      if (shufuFormId && !schema && shufuFormId !== formId) {
        setFormId(shufuFormId);
        setStartFlag(true);
      }
    }
  }, [formId, schema]);

  const loginWithTestCellphoneCode = useCallback(async () => {
    try {
      const result = await verifyCodeReturnUsersOrRegister('SPEECH_ROBOT_' + new Date().getTime(), '9999', '');
      console.log("result====================");
      console.log(result);
      const userId = result.data.userId || result.data.userid; // there's maybe a typo from backend
      const token = result.data.token;
      const organizationId = getParameterByName('organizationId');
      initUser(userId, organizationId, token);
    } catch (err) {
      console.error(err);
    }
  }, []);


  const getServiceInstance = useCallback(async () => {
    await loginWithTestCellphoneCode();
    // 获取url中的sceneId，并存储到state中
    // const sceneId = getParameterByName('sceneId');
    // const sceneId = getSessionFormId();
    console.log('location:state:', location.state)
    const sceneId = location.state.formId;
    const type = location.state.type;
    console.log('location:state:sceneId', sceneId)
    console.log('location:state:type', type)
    setSceneId(sceneId);
    setType(type);
    // const sceneId = 'b643c8f65f8e48a';
    messageEventEmitter.on('message', messageText => {
      const fullMessage = JSON.parse(messageText);
      console.log('fullMessage--------------------------------');
      console.log(fullMessage);
      localStorage.setItem('_messageJSON', JSON.stringify(fullMessage));
      onReceiveMessage(fullMessage);
    });
    try {
      await initShuaiCloudFromClientInstance();
    } catch (e) {
      console.error(e);
    }
    try {
      await tryStartNewServiceInstance(sceneId);
    } catch (e) {
      console.error(e);
    }
  }, [location.state, loginWithTestCellphoneCode, onReceiveMessage, tryStartNewServiceInstance]);

  useEffect(() => {
    getServiceInstance();
  }, [getServiceInstance]);

  function getServiceitemInfo(serviceItem, qrCodeOption) {
    let serviceItemWFV =
      (serviceItem &&
        serviceItem.serverParam &&
        serviceItem.serverParam.variables &&
        serviceItem.serverParam.variables.wfVariables) ||
      '{}';
    try {
      serviceItemWFV = JSON.parse(serviceItemWFV);
    } catch (e) { }
    let qrCodeWFV = qrCodeOption.wfVariables || '{}';
    try {
      qrCodeWFV = JSON.parse(qrCodeWFV);
    } catch (e) { }
    let wfVariables = {
      ...serviceItemWFV,
      ...qrCodeWFV
    };
    return {
      wfVariables
    };
  }

  function startTest() {
    if (window.android) window.android.onJSProcessStartOrEnd('start');
    props.history.push({ pathname: '/swipes', state: { formId, productType: type } });
  }

  function initUser(userId, organizationId, token) {
    setCurrentUserId(userId);
    setOrganizationId(organizationId);
    setToken(token);
  }

  function audioControlButtons() {
    return <div
      style={{ position: 'absolute', top: 100, right: 20, backgroundColor: 'white', color: '#2979FF' }}
      onClick={() => recorderState === 'loading' ? null : recorderState === 'open' ? stopRecorder() : startRecorder()}
    >
      {recorderState === 'loading' ? "..." : recorderState === 'open' ? '正在录音' : '没在录音'}
      <div>{dictationText}</div>
    </div>
  }
  function startRecorder() {
    const updatedIatRecorder = new IatRecorder({ id: 'welcome' });
    updatedIatRecorder.start();
    setRecorderState('loading');
    updatedIatRecorder.onOpen = (id) => {
      setRecorderState('open');
    };
    updatedIatRecorder.onClose = (id) => {
      setRecorderState('closed');
    };
    updatedIatRecorder.onTextChange = (text, id) => {
      setDictationText(text);
      if (text.indexOf('开始') !== -1 && startFlag) {
        stopRecorder()
        startTest()
      }
    }
    setIatRecorder(updatedIatRecorder);
  }

  function stopRecorder() {
    setRecorderState('stopped');
    console.log("stopping recorder");
    if (iatRecorder) iatRecorder.stop();
  }

  return (
    <div>
      <div className='welcome-page'>
        <div className='title'>
          欢迎使用舒辅智慧助手！😜
          </div>
        <div className='subtitle'>
          您可以对我说🗣“开始”或点击下方按钮，体验我们的智慧助手产品📈。
          </div>
        <div>
          <Button type="primary" size='large' disabled={!startFlag} className='btn-start' onClick={() => startTest()} >
            {startFlag ? '开始' : '加载中...'}
          </Button>
        </div>
        {/* {audioControlButtons()} */}
      </div>
    </div>
  )
}