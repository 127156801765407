import React, { useState, useEffect, useRef, useCallback, Component } from 'react';
import { Carousel } from 'antd';
import './slides.css';

import MessageBlockText from '../../components/MessageBlockText.js';
import MessageBlockSelect from '../../components/MessageBlockSelect.js';
import { playTextToSpeech } from '../../components/TextToSpeechMessage.js';
import _ from 'lodash';
import {
  getSessionFormId
} from '../../utils/localStorage.js';

import ShufuFormChat from '../../modules/ShufuFormChat.js';
import {
  getJsonFormById
} from '../../modules/shufu';

import IatRecorder from '../../utils/speechRecgUtil/IatRecorder';

export default function Slides(props) {
  const [messages, setMessages] = useState([]);
  const [stayMessage, setStayMessages] = useState(0); // 当前页数
  const [canChangePage, setCanChangePage] = useState(true);
  const carousel = useRef(null);
  const [iatRecorder, setIatRecorder] = useState(null);
  const [dictationText, setDictationText] = useState('');
  const [recorderState, setRecorderState] = useState('closed');
  const [shufuFormChat, setShufuFormChat] = useState(null);
  const [answerList, setAnswerList] = useState([]); // 已经回答的答案列表
  const [allAnswerSubmit, setAllAnswerSubmit] = useState(false); // 答题是否完毕

  // TODO 获取数据
  useEffect(() => {
    const formId = getSessionFormId();
    getForm(formId);
  }, []);

  // 添加鼠标按下抬起动作监听
  useEffect(() => {
    const body = document.getElementsByTagName('body')[0];
    body.onmousedown = function (ev) {
      var ev = ev || event;
      var mousedownX = ev.clientX;
      document.onmouseup = function (ev) {
        var ev = ev || event;
        var mouseupX = ev.clientX;
        if (mouseupX - mousedownX > 50 && stayMessage > 0 && canChangePage) {
          handlePrev();
        }
        if (mousedownX - mouseupX > 50 && stayMessage < messages.length - 1 && canChangePage) {
          handleNext();
        }
        document.onmousemove = null;
      }
    }
  });


  const submitFormHandler = useCallback(async () => {
    try {
      await sendActionLogs();
    } catch (e) {
      //alert error
    }
    let answerObject = shufuFormChat.convertSubmitModel();
    let messageJson = props.messageJson;
    answerObject.interaction.isReply = true;
    const messageId = props.messageId;
    console.log('messageId', messageId);

    const text = JSON.stringify(answerObject);

    let body = {
      from: props.message.from,
      to: props.message.to,
      'msg-id': props.message['msg-id'],
      timestamp: new Date(props.message.timestamp).getTime(),
      data: {
        _lctext: text,
        _lcattrs: {
          ...messageJson._lcattrs,
          processId: answerObject.interaction.processId, // FIXME workaround here
          businessKey: answerObject.interaction.businessKey // FIXME workaround here
        },
        _lctype: -1 // <-- not sure what's this..., just use -1 by default
      }
    };
    // window.$$f7.dialog.preloader('提交中');
    setCanEdit(false);
    try {
      await replyToForm(body);
      deleteFormStatus(messageId);
    } catch (e) {
      //alert error
    }
    // window.$$f7.dialog.close();

    console.log({ messageJson, answerObject });

    // console.log({ messageJson, answerObject });
  }, [shufuFormChat, props.messageJson, props.messageId, props.message]);

  // add answer question emitter
  useEffect(() => {
    if (props.isLastMessage) {
      answerBoardEmitter.on('submit', submitFormHandler);
    }

    return () => {
      answerBoardEmitter.removeListener('submit', submitFormHandler);
    };
  }, [props, submitFormHandler]);

  // 获取forms
  async function getForm(formId) {
    try {
      let jsonForm = await getJsonFormById(formId);
      let form = JSON.parse(jsonForm.form);
      let result = _.merge(form, {});
      let shufuFormChat = new ShufuFormChat({
        newMessage: result,
        language: 'zh',
        createQuestionMessage: createQuestionMessage,
        createAnswerMessage: createAnswerMessage,
        isReplyProcessedSuccess: () => {
          return false;
        }
      });
      console.log('===========11=======result=================')
      console.log(result)
      const data = shufuFormChat.getinitialState();
      let messageObject = _.get(data, 'messageObject', {});
      messageObject = Object.values(messageObject);
      console.log('==================messageObject=================')
      console.log(messageObject)
      setMessages(messageObject);
      // setMessages([messageObject[0],messageObject[messageObject.length-1]]);
      playTextToSpeech(messageObject[0].title_zh, startRecorder);
    } catch (e) {
      // do nothing
    }
  }

  function createQuestionMessage(
    questionId,
    messageObject,
    language = 'zh',
    model
  ) {
    // console.log('CREATE QUESTION MESSAGE', {questionId, messageObject, language, model})
    const question = messageObject[questionId];
    const { information_zh: information, questionNumber, type, questionsToDisplay } = question;
    const titleSwitch = `title_${language}`;
    const title =
      question[titleSwitch] || question.title;
    const badge = question.badge;

    let relatedQuestionsList = null;
    if (questionsToDisplay) {
      relatedQuestionsList = questionsToDisplay.map(question => {
        const path = question.path;

        const displayAnswers = JSON.parse(question.displayAnswers) || [];
        const questionTitle = messageObject[path] && messageObject[path].title_zh;

        const [sectionKey, questionKey] = path.split('/')
        const userAnswer = model[sectionKey] && model[sectionKey][questionKey];
        if (!userAnswer) {
          return null
        }
        const needAdd = displayAnswers.some((da) => {
          return userAnswer.match(new RegExp(`${da}$`))
        })
        if (needAdd) {
          return {
            title: questionTitle,
            answer: parseLanguage(messageObject[path] && messageObject[path].enum[userAnswer])
          }

        } else {
          return null
        }


      }).filter(q => !!q)
    }

    return {
      _id: `${questionId}`,
      direction: 'received',
      questionId,
      text: title,
      information,
      questionNumber,
      type,
      badge,
      relatedQuestionsList: relatedQuestionsList
    };
  }

  function createAnswerMessage(currentQuestionId, answerKey, answerVal, question) {
    let text = '';

    if (Array.isArray(answerVal)) {
      // alert('WA');
      if (question.viewType === 'uploadImage') {
        text = undefined;
      } else {
        text = answerVal.map(answer => parseAnswerText(answer)).join(', ');
      }
    } else if (question.viewType === 'date') {
      let selectDate = new Date(answerVal);

      text = selectDate.getFullYear() + '-';
      text +=
        (selectDate.getMonth() + 1 < 10
          ? `0${selectDate.getMonth() + 1}`
          : `${selectDate.getMonth() + 1}`) + '-';
      text +=
        selectDate.getDate() < 10
          ? `0${selectDate.getDate()}`
          : selectDate.getDate();
    } else {
      text = parseAnswerText(answerVal);
      // text=answerVal;
    }

    if (getParameterByName('lang') === 'tc') {
      text = Chinese.s2t(text);
    }
    return {
      _id: `${currentQuestionId}_answer`,
      text,
      type: 'answer', //! !!! THE LIB REQUIRES THIS KEY AND VALUE TO DETERMINE THE MESSAGE TYPE
      questionId: currentQuestionId,
      answer: answerKey,
      questionModel: question.model, //! !!! THE LIB REQUIRES THIS KEY AND VALUE TO DETERMINE THE QUESTION MODEL
      viewType: question.viewType,
      // avatarUrl: myavatarUrl,
      direction: 'sent'
    };
  }

  function audioControlButtons() {
    return <div
      className='dictation-text-content'
      onClick={() => recorderState === 'loading' ? null : recorderState === 'open' ? stopRecorder() : startRecorder()}
    >
      {recorderState === 'loading' ? "..." : recorderState === 'open' ? '正在录音' : '没在录音'}
      <div className='dictation-text'>{dictationText}</div>
    </div>
  }

  function startRecorder(type = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa') {
    console.log('开始录音//////////////////////////////////////////////////////' + stayMessage);
    console.log(type);
    const updatedIatRecorder = new IatRecorder({ id: 'slide_' + stayMessage });
    updatedIatRecorder.start();
    setRecorderState('loading');
    updatedIatRecorder.onOpen = (id) => {
      console.log('open**********************************************');
      if (id === 'slide_' + stayMessage) {
        setRecorderState('open');
      }
    };
    updatedIatRecorder.onClose = (id) => {
      console.log('closed************************************');
      if (id === 'slide_' + stayMessage) {
        setRecorderState("closed");
      }
    };
    updatedIatRecorder.onTextChange = (text, id) => {
      console.log('=====================================', text);
      if (text) {
        setDictationText(text.replace('。', ''));
      }
    }
    setIatRecorder(updatedIatRecorder);
  }
  useEffect(() => {
    console.error('++++++++++++++++++++++++++++++++++' + dictationText);
  }, [dictationText])

  function stopRecorder(type = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa') {
    console.log('停止录音///////////////////////////////////////////////////////////////////////////////////////////');
    console.log(type);
    setRecorderState('stopped');
    if (iatRecorder) iatRecorder.stop();
    setTimeout(() => {
      setIatRecorder(null);
    }, 200);
  }
  // 往前一页
  function handlePrev() {
    setStayMessages(stayMessage - 1);
    console.log('handlePrev=========1111111111111111111111111111111111111111==============');
    // setCanChangePage(false);
    carousel.current.prev();
  }

  // 往后一页
  function handleNext() {
    // stopRecorder();
    setStayMessages(stayMessage + 1);
    console.log('handleNext=========1111111111111111111111111111111111111111==============');
    // setCanChangePage(false);
    setDictationText('')
    carousel.current.next();
    setTimeout(() => {
      console.log("iatRecorderiatRecorderiatRecorderiatRecorderiatRecorderiatRecorderiatRecorderiatRecorder");
      console.log(iatRecorder);
    }, 5000);
  }

  // 提交答案
  function submitAnswer(answer) {
    stopRecorder('submitAnswer/////////////////////////////');
    // console.log('submit*************************************************************************');
    console.error(stayMessage, answer);
    // handleNext();
    // return;
    setTimeout(() => {
      console.error('9999999999999999999999999999999999999999');
      console.log(stayMessage);
      console.log(messages);
      console.log(canChangePage);
      if (stayMessage < messages.length - 1 && canChangePage) {
        // console.log(shufuFormChat);
        const tmpAnswerList = Object.assign([], answerList);
        tmpAnswerList.push(answer);
        setAnswerList(tmpAnswerList);
        let overFlag = true; // 答题完毕
        for (let index = stayMessage + 1; index < messages.length; index++) {
          const message = messages[index];
          let askedif = _.get(message, 'askedif', false);
          // console.log(tmpAnswerList);
          // console.log(askedif);
          if ((_.union(tmpAnswerList, askedif).length < (tmpAnswerList.length + askedif.length)) || !askedif) {
            // console.log('message================-----------88888888888888888888888888888888888888888');
            // console.log(message);
            // console.log(index);
            overFlag = false;
            setStayMessages(index);
            carousel.current.goTo(index, false);
            break;
          }
        }
        if (overFlag) {
          checkAllAnswerSubmit();
        }
      } else {
        checkAllAnswerSubmit();
      }
    }, 2000);
  }

  // 检查是否答题完毕
  function checkAllAnswerSubmit() {
    // if (!allAnswerSubmit) {
    //   setAllAnswerSubmit(true);
    //   alert('答题完毕');
    // }
  }

  // 滑动动作完成（防止重复调用滑动方法失效）
  function onAfterChange(current) {
    console.log('onAfterChange=========1111111111111111111111111111111111111111==============');
    console.log(current);
    setDictationText('')
    setCanChangePage(true);
    playTextToSpeech(messages[current].title_zh);
  }

  // 滑动动作完成（防止重复调用滑动方法失效）
  // const onAfterChange = useCallback(() => {
  //   setCanChangePage(true);
  // }, []);

  /**
   * 加载组件
   * 
   * case: single-choice => schema.type === 'string' && schema.enum
   * case: multiple-choice => schema.type === 'array'
   * case: number => schema.type === 'number'
   * case: string-input => schema.type === 'string' && !schema.enum
   * 
   */
  function getContent(message, messageIndex) {
    const messageType = _.get(message, 'type', '');
    if ((messageType === 'string' && message.enum) || (messageType === 'array')) {
      return (
        <MessageBlockSelect
          key={messageIndex}
          dictationText={stayMessage === messageIndex ? dictationText : false}
          message={message}
          submitAnswer={submitAnswer}
        ></MessageBlockSelect>
      );
    } else if ((messageType === 'number') || (messageType === 'string' && !message.enum)) {
      return (
        <MessageBlockText
          key={messageIndex}
          dictationText={stayMessage === messageIndex ? dictationText : false}
          matching={recorderState === 'loading' || recorderState === 'open'}
          message={message}
          submitAnswer={submitAnswer}
        ></MessageBlockText>
      );
    } else {
      return (
        <div></div>
      );
    }
  }

  return (
    <div>
      {audioControlButtons()}
      <Carousel
        ref={carousel}
        dots={false}
        afterChange={onAfterChange}
      >
        {
          messages.map((message, messageIndex) => {
            return getContent(message, messageIndex);
          })
        }
      </Carousel>
    </div>
  )
}