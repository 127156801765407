import io from 'socket.io-client';

export default class shuaiCloud {
  constructor(props) {
    const token = encodeURIComponent(props.appAuthToken);
    const server = props.server;
    this.socket = io(
      `https://shuaicloud.siuvo.cn/user?token=${token}&server=${server}`,
      {
        transports: ['websocket', 'polling']
      }
    );
    this.on = this.on.bind(this);

    this.on('ERROR_LOGIN', props.onInitFailure);
    this.on('message', props.onMessage);
    this.on('connect', props.onConnect);
  }

  on(eventName, cb) {
    if (typeof cb === 'function') this.socket.on(eventName, cb);
  }

  sendMessage(message) {
    console.log(message);
  }
}
