import React, { useEffect, useState, useCallback, useRef } from "react";
import "./components.css";
import { Button } from "antd";
import _ from "lodash";

export default function MessageBlockSelect(props) {
  const { id, submitAnswer, message, isCurrent, dictationText, editPreviousAnswer, isFirst, categoryType, mmessagesShown } = props;

  console.log('isFirst-------------', isFirst);

  const noneOfTheAbove = "noneOfTheAbove";
  const [questionText, setQuestionText] = useState("");
  const [answerText, setAnswerText] = useState("");
  const [isSingleChoice, setSingleChoice] = useState(true); // true: 单选  false: 多选
  const [answerList, setAnswerList] = useState([]); // 答案的列表
  const [matchWordList, setMatchWordList] = useState([]); // 匹配词的列表
  const [matching, setMatching] = useState(true); // 判断是否正在匹配，如已经匹配到则停止匹配（单选起效）
  const [submitFlag, setSubmitFlag] = useState(true);

  const previousId = usePrevious(id);

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }


  // 匹配到单选选项自动选择答案并延时跳转
  const matchSendSingleChoice = useCallback((answerId) => {
    setMatching(false);
    setAnswerText(answerId);
    submitAnswer(answerId);
  }, [submitAnswer]);

  function judgeYesAnswer(dictation) {
    return (dictation.includes('是') || dictation.includes('有') || dictation.includes('对')) &&
      !dictation.includes('不') && !dictation.includes('没')
  }

  function judgeNoAnswer(dictation) {
    return dictation.includes('不') || dictation.includes('没')
  }

  // 匹配单选选项
  const matchSingleChoice = useCallback((dictationText) => {
    // 判断2个选项时是否匹配A包含B的选项
    if (_.get(answerList, 'length', 0) === 2) {
      const answer1 = answerList[0];
      const answer2 = answerList[1];
      // 判断是否题目说包含“不，没”的时候能匹配到“否”，说“对，有，是”的时候匹配到“是”
      if (answer1.name === '是') {
        if (judgeYesAnswer(dictationText)) {
          matchSendSingleChoice(answer1.id);
          return;
        } else if (judgeNoAnswer(dictationText)) {
          matchSendSingleChoice(answer2.id);
          return;
        }
      }
      if (answer2.name === '是') {
        if (judgeYesAnswer(dictationText)) {
          matchSendSingleChoice(answer2.id);
          return;
        } else if (judgeNoAnswer(dictationText)) {
          matchSendSingleChoice(answer1.id);
          return;
        }
      }
      if ((answer1.name.includes(answer2.name)) && (dictationText.includes(answer1.name) && dictationText.includes(answer2.name))) {
        matchSendSingleChoice(answer1.id);
        return;
      } else if (answer2.name.includes(answer1.name) && (dictationText.includes(answer1.name) && dictationText.includes(answer2.name))) {
        matchSendSingleChoice(answer2.id);
        return;
      }
    }
    const matchedAnswer = answerList.filter((answer) => {
      return dictationText.indexOf(answer.name) >= 0;
    });
    if (matchedAnswer.length === 1) {
      matchSendSingleChoice(matchedAnswer[0].id);
    } else {
      for (let matchWordIndex in matchWordList) {
        let match_words = _.get(
          matchWordList[matchWordIndex],
          "match_words",
          []
        );
        const matchedWords = match_words.filter((matchWord) => {
          return dictationText.indexOf(matchWord) >= 0;
        });
        if (matchedWords.length > 0) {
          matchSendSingleChoice(matchWordIndex);
        }
      }
    }
  }, [answerList, matchSendSingleChoice, matchWordList]);


  // 匹配多选选项
  const matchMultipleChoice = useCallback((dictationText) => {
    if ((dictationText.indexOf('提交') >= 0 || dictationText.indexOf('踢脚') >= 0 || dictationText.indexOf('洗脚') >= 0 || dictationText.indexOf('下一题') >= 0) && answerText.length > 0) {
      submitAnswer(answerText);
      return;
    }
    // 匹配到的所有答案
    let matchedAnswer = answerList.filter((answer) => {
      return dictationText.indexOf(answer.name) >= 0;
    });
    // 是否包含全选
    const allMatchedAnswer = matchedAnswer.filter((answer) => {
      return answer.id === "noneOfTheAbove";
    });
    let noneOfTheAboveMatchWords = _.get(matchWordList, ['noneOfTheAbove', 'match_words'], []);
    let noneOfTheAboveMatchedWord = noneOfTheAboveMatchWords.filter((matchWord) => {
      return dictationText.indexOf(matchWord) >= 0;
    });
    if (allMatchedAnswer.length > 0 || noneOfTheAboveMatchedWord.length > 0) {
      matchSendSingleChoice([noneOfTheAbove]);
    } else {

      // 获取直接匹配成功的id
      // matchedAnswer = answerList.filter((answer) => {
      //   if (dictationText.indexOf(answer.name) >= 0) {
      //     return answer.id;
      //   }
      //   return []
      // });
      let result = [];
      matchedAnswer.forEach((item) => {
        result.push(item.id)
      })
      // 获取匹配match_words成功的id
      let matchedWords = [], matchedAnswerResult = [...result];
      for (let matchWordIndex in matchWordList) {
        let match_words = _.get(
          matchWordList[matchWordIndex],
          "match_words",
          []
        );
        matchedWords = match_words.filter((matchWord) => {
          return dictationText.indexOf(matchWord) >= 0;
        });
        if (matchedWords.length > 0) {
          // 合并为一个不重复的数组
          matchedAnswerResult = _.union(result, [matchWordIndex]);
        }

        // const allChoiceIndex = tmpAnswerText.indexOf("noneOfTheAbove");
        // if (allChoiceIndex >= 0) {
        //   tmpAnswerText.splice(allChoiceIndex, 1);
        // }
        // else {
        //   matchedAnswerResult = result;
        // }
        // 去除全选
        const allChoiceIndex = matchedAnswerResult.indexOf("noneOfTheAbove");
        if (allChoiceIndex >= 0) {
          matchedAnswerResult.splice(allChoiceIndex, 1);
        }
      }
      setAnswerText(_.union(answerText, matchedAnswerResult))
    }
    return;
  }, [answerList, answerText, matchSendSingleChoice, matchWordList, submitAnswer]);


  // console.error(1+dictationText);
  useEffect(() => {
    // console.error(2+dictationText);
    // 收集语音词进行匹配
    if (dictationText) {
      if ((!isFirst || categoryType) && dictationText.includes('返回')) {
        if (submitFlag) editPreviousAnswer(categoryType);
        setSubmitFlag(false);
      } else {
        console.error('eeeeeeeeeeeeeeeeeeee///////////' + dictationText);
        if (isSingleChoice) {
          matchSingleChoice(dictationText);
        } else if (!isSingleChoice) {
          matchMultipleChoice(dictationText);
        }
      }
    }
    // }, [categoryType, dictationText, editPreviousAnswer, isFirst, isSingleChoice, matchMultipleChoice, matchSingleChoice, submitFlag])
  }, [dictationText])

  useEffect(() => {
    if (isCurrent && id !== previousId) {
      const tmpAnswerList = _.get(message, "enum", []);
      if (JSON.stringify(tmpAnswerList) === '{}') {
        setTimeout(() => {
          submitAnswer(undefined);
        }, 8000);
        return;
      }
    }
  }, [message, submitAnswer, isCurrent, id, previousId])


  useEffect(() => {
    if (_.get(message, "type", "") === "array") {
      setSingleChoice(false);
      setAnswerText([]);
    }
    setQuestionText(message.title_zh);
    const tmpMatchWordList = _.get(message, "enumInfo", []);

    const enums = _.get(message, "enum", {});
    const enumIcon = _.get(message, "enumIcon", {});
    const enumOrder = message.enumOrder || Object.keys(enums);
    const newAnswerList = enumOrder
      .filter((enumKey) => enumKey !== '__OTHER__')
      .map((enumKey) => {
        return {
          id: enumKey,
          name: enums[enumKey].split('|~|')[0],
          icon: _.get(enumIcon, enumKey, '')
        }
      });
    setAnswerList(newAnswerList);
    setMatchWordList(tmpMatchWordList);
    console.error('match_words------------:', tmpMatchWordList);
  }, [message, submitAnswer]);

  // 单选答案
  function singleChoiceAnswer(answerId) {
    matchSendSingleChoice(answerId);
    // setAnswerText(answerId);
    // // submitAnswer(answerId);
  }

  // 多选答案处理全选等问题
  function multipleChoiceAnswer(answerId) {
    if (answerId === noneOfTheAbove) {
      setAnswerText([noneOfTheAbove]);
    } else {
      const tmpAnswerText = [...answerText];
      const answerIndex = tmpAnswerText.indexOf(answerId);
      if (answerIndex >= 0) {
        tmpAnswerText.splice(answerIndex, 1);
      } else {
        tmpAnswerText.push(answerId);
      }
      const allChoiceIndex = tmpAnswerText.indexOf("noneOfTheAbove");
      if (allChoiceIndex >= 0) {
        tmpAnswerText.splice(allChoiceIndex, 1);
      }
      setAnswerText(tmpAnswerText);
    }
  }

  return (
    <div className="content">
      {/* {(!isFirst || categoryType) ? <Button
        type="primary"
        size="large"
        className="btn-skip"
        onClick={() => editPreviousAnswer(categoryType)}
      >
        LAST QUESTION
          </Button> : null} */}
      <div style={{ float: 'right', width: 200, wordBreak: 'break-all' }} dangerouslySetInnerHTML={{ __html: dictationText }}></div>
      <div className="flex-layout">
        <img
          src="/icons/robot.svg"
          className="robot-icon cannot-select"
          alt="logo"
        />
        <div className="question-text message-text cannot-select">
          <div className='question-type-text'>{isSingleChoice ? '单选题' : '多选题'}</div>
          <div dangerouslySetInnerHTML={{ __html: questionText }}></div>
        </div>
        {!isSingleChoice ? (
          <div className='message-friendly-remind'>
            <div className='message-friendly-remind-title'>温馨提示：</div>
            <div className='message-friendly-remind-text'>1、完成选择，您可以说：<font color='#2979ff'>提交或下一题</font></div>
            <div className='message-friendly-remind-text'>2、跳过此题，您可以说：<font color='#2979ff'>跳过</font></div>
            <div className='message-friendly-remind-text'>3、返回上一题，您可以说：<font color='#2979ff'>返回</font></div>
          </div>
        ) : null}
      </div>
      <div className="flex-layout">
        {answerList.map((answer, answerIndex) => {
          return isSingleChoice ? (
            <div style={{ position: 'relative' }} onClick={() => singleChoiceAnswer(answer.id)} key={answerIndex}>
              {
                answerText === answer.id ?
                  (
                    <div className='radio-answer-shape-inner'></div>
                  ) : null
              }
              {answer.icon ? null : (
                <div className={
                  answerText === answer.id ?
                    'radio-answer-shape radio-answer-shape-select'
                    : 'radio-answer-shape'
                }></div>
              )}
              <div
                className={
                  answerText === answer.id
                    ? "radio-answer radio-answer-active cannot-select"
                    : "radio-answer cannot-select"
                }
                style={{ padding: answer.icon ? '0 50px' : '0 60px 0 70px' }}
              >
                {answer.icon ? (<img src={answer.icon} style={{ width: 40, marginRight: 10, position: 'relative', top: -5 }}></img>) : null}
                {answer.name}
              </div>
            </div>
          ) : (
            <div style={{ position: 'relative' }} onClick={() => multipleChoiceAnswer(answer.id)} key={answerIndex}>
              {
                answerText.indexOf(answer.id) >= 0 ?
                  (
                    <div className={answer.id === noneOfTheAbove ? 'radio-answer-shape-inner' : 'checkbox-answer-shape-inner'}>{answer.id === noneOfTheAbove ? '' : '√'}</div>
                  ) : null
              }
              <div className={
                answer.id === noneOfTheAbove ? (
                  answerText.indexOf(answer.id) >= 0 ?
                    'radio-answer-shape radio-answer-shape-select'
                    : 'radio-answer-shape'
                ) : answerText.indexOf(answer.id) >= 0 ?
                  'checkbox-answer-shape checkbox-answer-shape-select'
                  : 'checkbox-answer-shape'
              }></div>
              <div
                className={
                  answerText.indexOf(answer.id) >= 0
                    ? "radio-answer radio-answer-active cannot-select"
                    : "radio-answer cannot-select"
                }
              >
                {answer.name}
              </div>
            </div>
          );
        })}
      </div>
      <div className="flex-layout">
        <img
          src="/icons/audio.svg"
          className="audio-icon cannot-select"
          alt="logo"
        />
        {isSingleChoice ? null : (
          <Button
            type="primary"
            size="large"
            className="btn-skip"
            onClick={() => submitAnswer(answerText)}
          >
            提交答案
          </Button>
        )}
      </div>
    </div>
  );
}
