import CONFIG from './config';
import axios from 'axios';
// import cookie from 'react-cookies';
import { getToken } from '../utils/localStorage.js';

const API_BASE_URL = CONFIG.backend.shufuLocal
  ? CONFIG.SHUFU.local.url
  : CONFIG.SHUFU.remote.url;

// const API_BASE_URL = 'http://apiv2.siuvo.com:7099/api/1.2/rest';

// const API_BASE_URL = "https://sit.shufu.siuvo.com/romeo/api/1.2/rest/"

// return a Promise
function _request(options) {
  // let _token = cookie.load('appAuthToken');
  let _token = getToken();
  let _options = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    method: 'GET',
    withCredentials: true,
    data: {},
    ...options,
    url: API_BASE_URL + options.url
  };
  if (_token) {
    _options.headers['Authorization'] = `Bearer ${_token}`;
  }

  let startTime = Date.now();
  return axios
    .request(_options)
    .then(res => {
      if (res.status === 200 || res.status === 201) {
        __bl &&
          __bl.api &&
          __bl.api(options.name, true, Date.now() - startTime, res.status);
        return res.data;
      } else {
        throw new Error(`${res.data.errorMessage}: ${res.data.errorCode}`);
      }
    })
    .catch(error => {
      if (
        error.response &&
        error.response.data &&
        error.response.data.errorMessage
      ) {
        __bl &&
          __bl.api &&
          __bl.api(
            options.name,
            false,
            Date.now() - startTime,
            error.response.status,
            `[${error.message}] (${error.response.data.errorCode}) ${error.response.data.errorMessage}`
          );

        // alert(
        //   `[${error.message}] (${error.response.data.errorCode}) ${error.response.data.errorMessage}`
        // );
        throw new Error(error.response.data.errorMessage);
      }
      __bl &&
        __bl.api &&
        __bl.api(
          options.name,
          false,
          Date.now() - startTime,
          error.response && error.response.status,
          `[${error.message}]`
        );
      throw new Error(error);
    });
}

export function verifyCodeReturnUsersOrRegister(
  cellphone,
  verificationCode,
  name
) {
  return _request({
    name: 'verifyCodeReturnUsersOrRegister',
    method: 'POST',
    url: `/user/verifyCodeReturnUsersOrRegisterWithRedis`,
    data: {
      cellphone,
      verificationCode,
      name
    }
  });
}

export function getQrcodeSceneData(sceneId) {
  return _request({
    name: 'getQrcodeSceneData',
    method: 'GET',
    url: `/user/wechatQr/${sceneId}`
  });
}

export function getServiceItem(serviceItemId) {
  return _request({
    name: 'getServiceItem',
    method: 'GET',
    url: `/service/item/by-id/${serviceItemId}`
  });
}

export async function queryMessage({
  userId,
  startTime,
  direction = 'NEW_TO_OLD',
  limit = 20,
  serviceInstanceId
}) {
  const url = `/messages/`;
  return await _request({
    name: 'queryMessage',
    method: 'GET',
    url,
    params: {
      visibleTo: userId,
      startTime,
      direction,
      size: limit,
      serviceInstanceId
    }
  });
}

export async function getJsonFormById(jsonFormId) {
  const url = `/form/jsonform/${jsonFormId}`;
  return await _request({
    name: 'getJsonFormById',
    method: 'GET',
    url
  });
}

export async function replyToForm(body) {
  return await _request({
    name: 'replyToForm',
    method: 'POST',
    url: `/messages/reply-to-form`,
    data: body
  });
}

export function startServiceItemWithCheckEncounter(option) {
  const {
    serviceItemId,
    customerId,
    wfVariables,
    name,
    tags,
    encounterId,
    forceStartNew,
    sceneId,
    tid
  } = option;
  const serverParam = {
    wfVariables: wfVariables ? wfVariables : '{}',
    taskVariables: '{}',
    tags: tags || []
  };
  let url = `/service/instance/startServiceCheckEncounterAndRestart/${serviceItemId}?customerId=${customerId}&instanceName=${name}`;
  if (encounterId) {
    url += `&encounterId=${encounterId}`;
  }
  if (forceStartNew) {
    url += `&forceStartNew=true`;
  }
  if (sceneId) {
    url += `&sceneId=${sceneId}`;
  }
  if (tid) {
    url += `&trackingId=${tid}`;
  }

  return _request({
    name: 'startServiceItemWithCheckEncounter',
    method: 'POST',
    url,
    data: serverParam
  });
}

export async function getAllComplaintsByTag(category, avSchemaIdSuffix, subcategories) {
  let url = `/medicalRecord/allChiefComplaintsByTag/${category}`;
  if (avSchemaIdSuffix || subcategories) url += `?`;
  if (avSchemaIdSuffix) url += `avSchemaIdSuffix=${avSchemaIdSuffix}&`;
  if (subcategories && subcategories.length > 0) url += `subcategories=${encodeURI(subcategories.join(","))}`;
  return await _request({
    name: 'getAllComplaintsByTag',
    method: 'GET',
    url
  });
}

export function getAllSymptoms(symptomVersion = "emr") {
  return _request({
    name: 'getAllSymptoms',
    method: 'GET',
    url: `/medicalRecord/getAllSymptoms?symptomVersion=${symptomVersion}`
  });
}