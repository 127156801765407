import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Button } from 'antd';
import './components.css'

export default function MessageBlockText(props) {


  const [questionText, setQuestionText] = useState('');
  const [submitFlag, setSubmitFlag] = useState(true); // 提交的三种状态（init,writing,ready）
  // const [matching, setMatching] = useState(true); // 判断是否正在语音输入，若语音结束则提交答案

  // const [stopMatching, setStopMatching] = useState(false); // 判断是否正在语音输入，若语音结束则提交答案

  let timer = null;

  // 收集语音词填写答案
  const { dictationText, isCurrent, id, editPreviousAnswer, submitAnswer, clearAnswer, isFirst, categoryType, mmessagesShown } = props;

  console.log('isFirst-------------', isFirst);

  const previousId = usePrevious(id);

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  // if (dictationText.includes('提交')) {
  //   setSubmitFlag(true);
  // }

  // setTimeout(() => {
  //   console.error('111111111111=========');
  //   dictationText = 'hhh';
  // }, 2000);

  // useEffect(() => {
  //   if (dictationText) {
  //     if (timer) {
  //       clearTimeout(timer);
  //       timer = null;
  //     }
  //     timer = setTimeout(() => {
  //       if (timer) {
  //         submitAnswer(dictationText);
  //         console.error('tttttttttt===================='+dictationText);
  //       }
  //     }, 5000);
  //   }
  // }, [dictationText])

  // useEffect(() => {
  //   // if (dictationText && !matching && submitFlag) {
  //     setSubmitFlag('ready');
  //     setTimeout(() => {
  //       if (submitFlag) {

  //       }
  //     }, 5000);
  //   // }
  // }, [submitFlag])

  const skipAnswer = useCallback(() => {
    submitAnswer('SKIPPED');
  }, [submitAnswer]);

  useEffect(() => {
    if (isCurrent) {
      if ((!isFirst || categoryType) && dictationText.includes('返回')) {
        if (submitFlag) editPreviousAnswer(categoryType);
        setSubmitFlag(false);
      } else {
        // 识别说“提交”时可能会出现的语音匹配词
        if (dictationText.includes('提交') || dictationText.includes('踢脚') || dictationText.includes('洗脚') || dictationText.includes('下一题')) {
          if (submitFlag) submitAnswer(dictationText.replace('提交', '').replace('踢脚', '').replace('洗脚', '').replace('下一题', ''));
          setSubmitFlag(false);
        }
        if (dictationText.includes('跳过')) {
          if (submitFlag) skipAnswer();
          setSubmitFlag(false);
        } else {
          if (dictationText.includes('提交')) {
            if (submitFlag) submitAnswer(dictationText.replace('提交', ''));
            setSubmitFlag(false);
          }
          if (dictationText.includes('跳过')) {
            if (submitFlag) skipAnswer();
            setSubmitFlag(false);
          }
        }
      }
    }
  }, [categoryType, dictationText, editPreviousAnswer, isCurrent, isFirst, skipAnswer, submitAnswer, submitFlag]);

  useEffect(() => {
    setQuestionText(props.message.title_zh);
  }, [props.message.title_zh]);

  return (
    <div className='content'>
      {/* {(!isFirst || categoryType) ? <Button
        type="primary"
        size="large"
        className="btn-skip"
        onClick={() => editPreviousAnswer(categoryType)}
      >
        LAST QUESTION
          </Button> : null} */}
      <div style={{ float: 'right', width: 100, wordBreak: 'break-all' }}>
        {dictationText}
      </div>
      <div className='flex-layout'>
        <img src='/icons/robot.svg' className='robot-icon cannot-select' alt='logo' />
        <div className='question-text message-text cannot-select' dangerouslySetInnerHTML={{ __html: questionText }}></div>
        <div className='message-friendly-remind'>
          <div className='message-friendly-remind-title'>温馨提示：</div>
          <div className='message-friendly-remind-text'>1、完成填写，您可以说：<font color='#2979ff'>提交或下一题</font></div>
          <div className='message-friendly-remind-text'>2、修改内容，您可以说：<font color='#2979ff'>清空</font></div>
          <div className='message-friendly-remind-text'>3、跳过此题，您可以说：<font color='#2979ff'>跳过</font></div>
          <div className='message-friendly-remind-text'>4、返回上一题，您可以说：<font color='#2979ff'>返回</font></div>
        </div>
      </div>
      <div className='flex-layout'>
        <div className='answer-text message-text cannot-select'>{dictationText}</div>
        <img src='/icons/user.svg' className='user-icon cannot-select' alt='logo' />
      </div>
      <div className='flex-layout' style={{ float: 'right' }}>
        <Button type='primary' size='large' className='btn-skip' onClick={()=>clearAnswer()} >
          清空
        </Button>
        <Button type='primary' size='large' className='btn-skip' onClick={skipAnswer} >
          跳过
        </Button>
      </div>
    </div>
  );
}
