import React, { useState, useEffect } from 'react';
import './header.css';

export default function Header(props) {
  const [count, setCount] = useState(0);
  const [pagePath, setPagePath] = useState(window.location.href.split('/#/')[1]);
  const homeUrl = window.location.href.split('/#/')[0];

  useEffect(() => {
    // console.log("added event listener");
    const handlePageChange = () => {
      // console.log("url: ", window.location.href);
      setPagePath(window.location.href.split('/#/')[1]);
    };
    window.addEventListener('hashchange', handlePageChange, false);

  }, []);


  return (
    <div>
      <div className='nav'>
        <img src='/icons/logo_banner.png' className="logo" alt="logo" onClick={() => {
          if (count >= 5) {
            let vConsoleDom = document.getElementById('__vconsole');
            if (vConsoleDom) {
              let vConsoleDisplay = vConsoleDom.style.display;
              vConsoleDom.style.display = vConsoleDisplay === 'block' ? 'none' : 'block';
            }
            setCount(0);
          } else {
            setCount(count + 1);
          }
        }} />
        {pagePath ? <div
          className="home-page-button"
          onClick={() => {
            if (window.android) window.android.OnJSReloadPage();
            else {
              window.location.href = homeUrl;
            }
          }}>
          <img height={80} width={80} src='data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9JzMwMHB4JyB3aWR0aD0nMzAwcHgnICBmaWxsPSIjMjk3OWZmIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMiAzMiIgeD0iMHB4IiB5PSIwcHgiPjx0aXRsZT5BcnRib2FyZCAzODwvdGl0bGU+PGc+PHBhdGggZD0iTTI2LjYzLDEzLjIybC0xMC04YTEsMSwwLDAsMC0xLjI0LDBsLTEwLDhhMSwxLDAsMSwwLDEuMjQsMS41NmwuMzgtLjNWMjRhMywzLDAsMCwwLDMsM0gyMmEzLDMsMCwwLDAsMy0zVjE0LjQ4bC4zOC4zQTEsMSwwLDAsMCwyNiwxNWExLDEsMCwwLDAsLjc4LS4zN0ExLDEsMCwwLDAsMjYuNjMsMTMuMjJaTTE0LDI1VjIwYTEsMSwwLDAsMSwxLTFoMmExLDEsMCwwLDEsMSwxdjVabTktMTJWMjRhMSwxLDAsMCwxLTEsMUgyMFYyMGEzLDMsMCwwLDAtMy0zSDE1YTMsMywwLDAsMC0zLDN2NUgxMGExLDEsMCwwLDEtMS0xVjEzczAtLjA3LDAtLjFsNy01LjYyLDcsNS42MlMyMywxMywyMywxM1oiPjwvcGF0aD48L2c+PC9zdmc+' />
        </div> : null}
      </div>
    </div>
  )
}