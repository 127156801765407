import { messageEventEmitter, initClient } from './index.js';
import { getServiceInstanceId } from '../../utils/localStorage.js';
// import i18n from '../../i18n.js';


let _client = null;

export function initShuaiCloud() {
  return new Promise((resolve, reject) => {
    if (_client) {
      resolve(_client);
      return _client;
    }
    const client = initClient({
      onMessage: message => {
        console.log('-----------收到消息-----内容如下-------')
        console.log(message)
        if (message.messageType === 'CONVERSATION_MESSAGE') {
          // 发送给对话的消息
          console.log('ON CONVERSATION_MESSAGE');
          messageEventEmitter.emit('message', message.message);
        }

        if (message.messageType === 'USER_MESSAGE') {
          const fullMessage = JSON.parse(message.message);
          console.log('ON USER_MESSAGE', { fullMessage });
          // 答题超时（30min）后弹窗提醒用户并刷新页面

          if (_.get(fullMessage, '_lcattrs.type', '') === 'systemNotification') {
            if (_.get(fullMessage, '_lcattrs.notificationType', '') === 'processTerminated') {
              console.log('ssid: ', _.get(fullMessage, '_lcattrs.ssid'));
              console.log('serviceInstanceId: ', getServiceInstanceId());
              // if (_.get(fullMessage, '_lcattrs.ssid') === getServiceInstanceId()) {
              //   window.$$f7.dialog.alert(i18n.t('process_terminated'), false, function () {
              //     window.location.reload();
              //   });
              // }
            }
          }
        }
      },
      onConnect: () => {
        console.log('ON CONNECT');
        _client = client;
        resolve(client);
      }
    });
    setTimeout(() => {
      reject('Connection time out', client);
    }, 10000); // 10 s time out
  });
}
