import { Button } from 'antd';
import _ from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import VConsole from 'vconsole';
import MessageBlockSelect from '../../components/MessageBlockSelect.js';
import MessageBlockText from '../../components/MessageBlockText.js';
import { initShuaiCloud as initShuaiCloudFromClientInstance } from '../../modules/shuaicloud/clientInstance';
import { messageEventEmitter } from '../../modules/shuaicloud/index.js';
import {
  getAllComplaintsByTag,
  getAllSymptoms, getJsonFormById, replyToForm
} from '../../modules/shufu';
import ShufuFormChat from '../../modules/ShufuFormChat.js';
import {
  deleteFormStatus
} from '../../utils/localStorage.js';
import './swipes.css';



const createQuestionMessage = (
  questionId,
  messageObject,
  language = zh,
  model
) => {
  // console.log('CREATE QUESTION MESSAGE', {questionId, messageObject, language, model})
  const question = messageObject[questionId];
  console.log('questionId----------------------1', messageObject);
  console.log('questionId----------------------2', questionId);
  console.log('questionId----------------------3', question);
  const { information_zh: information, questionNumber, type, questionsToDisplay } = question;
  const titleSwitch = `title_${language}`;
  const title =
    question[titleSwitch] || question.title;
  const badge = question.badge;

  let relatedQuestionsList = null;
  if (questionsToDisplay) {
    relatedQuestionsList = questionsToDisplay.map(question => {
      const path = question.path;

      const displayAnswers = JSON.parse(question.displayAnswers) || [];
      const questionTitle = messageObject[path] && messageObject[path].title_zh;

      const [sectionKey, questionKey] = path.split('/')
      const userAnswer = model[sectionKey] && model[sectionKey][questionKey];
      if (!userAnswer) {
        return null
      }
      const needAdd = displayAnswers.some((da) => {
        return userAnswer.match(new RegExp(`${da}$`))
      })
      if (needAdd) {
        return {
          title: questionTitle,
          answer: parseLanguage(messageObject[path] && messageObject[path].enum[userAnswer])
        }

      } else {
        return null
      }


    }).filter(q => !!q)
  }

  return {
    _id: `${questionId}`,
    direction: 'received',
    questionId,
    text: title,
    information,
    questionNumber,
    type,
    badge,
    relatedQuestionsList: relatedQuestionsList
  };
}


const updateMessageWithAnswer = (messageToBeUpdated, answer, answerVal) => {
  let newMessage = messageToBeUpdated;
  const answerObject = {
    text: answerVal,
    answer: answer
  }
  newMessage.answer = answerObject;
  return newMessage
}

// let reactSwipeEl;
export default function Swipes(props) {
  const [message, setMessage] = useState([]);
  const [stayMessage, setStayMessages] = useState(0); // 当前页数
  const carousel = useRef(null);
  const [shufuFormChat, setShufuFormChat] = useState(null);
  const [answerList, setAnswerList] = useState([]); // 已经回答的答案列表
  // const [allAnswerSubmit, setAllAnswerSubmit] = useState(false); // 答题是否完毕
  const [formState, setFormState] = useState({ messagesShown: [] });
  const [nextFormState, setNextFormState] = useState(null);
  const [messageShownLsit, setMessageShownList] = useState([]); // 存放用于渲染界面的数组，除了messageShown中包含的问题之外，还有submit时的界面
  const [pageInfo, setPageInfo] = useState(null);
  const [nextPageInfo, setNextPageInfo] = useState(null);
  const [completeFlag, setCompleteFlag] = useState(false); // 判断是否完成所有题目
  const [openReport, setOpenReport] = useState(false); // 判断是否展开报告
  const [completeURL, setCompleteURL] = useState(''); // 完成所有题目后的报告url
  // const [nextPage, setNextPage] = useState(null); // 存放下一题信息并完成跳题动画
  const transformSecond = 0.5; // 动画持续时间
  const answerWaitSecond = 2; // 答题延迟跳转
  const nextFormWaitSecond = 3; // 跳转到下一张表的延迟时间
  // const [_currentQuestion, setCurrentQuestion] = useState(null);
  const [formId, setFormId] = useState(null);
  const [submitFlag, setSubmitFlag] = useState(true);
  // const [isCreateShufuFormchat, setIsCreateShufuFormchat] = useState(true); // 选择部位和症状的时候，外面两层结构不通过shufuformchat
  const [symptomList, setSymptomList] = useState(null); // 保存主诉选择的整体对象
  const [selectedBodyPart, setSelectedBodyPart] = useState({}); // 保存主诉选择的对象中的部位对象
  const [selectedChiefComplaints, setSelectedChiefComplaints] = useState(null); // 保存主诉选择的对象中的症状对象
  const [chooseChiefComplaintInstructions, setChooseChiefComplaintInstructions] = useState(null); // 保存主诉选择的对象中的症状对象
  const [dictationText, setDictationText] = useState('');
  const [swipeNext, setSwipeNext] = useState(true); // 判断是向后滑动还是向前滑动，true：向后，false：向前
  const [categoryType, setcategoryType] = useState(false); // 判断症状是否可以跳过
  const [bodyPartFormState, setBodyPartFormState] = useState(null); // bodyPart的formState数据
  const [chiefComplaintsFormState, setChiefComplaintsFormState] = useState(null); // chiefComplaints的formState数据
  const [psychSymptomsFormState, setPsychSymptomsFormState] = useState(null); // psychSymptoms的formState数据
  const [categoryFormFlag, setCategoryFormFlag] = useState(false); // 判断是否是症状的form,如果是，可以从从第一题跳转回选择症状
  const [labTestItems, setLabTestItems] = useState(null); // 体检项目推荐的labtests
  const [psychSymptomList, setPsychSymptomList] = useState(null); // 精总流程，选择症状

  const [messageText, setMessageText] = useState(null);

  // 测试
  window.onReceiveRobotText = onReceiveRobotText;
  function onReceiveRobotText(text) {
    console.error(text);
    if (text.includes('清空') || text.includes('重新输入') || text.includes('重新填写')) {
      setDictationText('');
      return;
    }
    setDictationText(dictationText + text);
  }

  const setNewFormState = useCallback((nextFormState, _nextPageInfo) => {
    console.log('nextFormState-----------------------------------------');
    console.log(nextFormState);
    console.log(_nextPageInfo);
    setNextFormState(nextFormState);
    setNextPageInfo(_nextPageInfo);
    const timer = setTimeout(() => {
      setDictationText('');
      if (nextFormState) {
        setFormState(nextFormState);
        setNextFormState(null);
      }
      if (_nextPageInfo) {
        setPageInfo(_nextPageInfo);
        // onCallPlayText(nextPageInfo.text);
        setNextPageInfo(null);
        if (_.get(nextFormState, 'currentQuestion', '') !== 'SUBMIT') onCallPlayText(_nextPageInfo.text);
      }
    }, transformSecond * 1000);
    return () => clearTimeout(timer);
  }, []);

  // 调试框暗门
  useEffect(() => {
    new VConsole();
  }, []);

  // 获取体检推荐项目form
  const getDemoInvoiceForm = useCallback(async (shufuFormId) => {
    try {
      const jsonForm = await getJsonFormById(shufuFormId);
      const form = JSON.parse(jsonForm.form);
      // 获取推荐的item
      const labTestItems = _.get(form, ['schema', 'properties', 'labTests', 'items'], []);
      setLabTestItems(labTestItems);
      setTimeout(() => {
        setCompleteFlag(true);
      }, 0)
    } catch (e) {
      // do nothing
    }
  }, []);

  // 获取forms
  const getForm = useCallback(async (formId, messageJSON, nextForm = null) => {
    try {
      const jsonFormEntity = await getJsonFormById(formId);
      const fullForm = JSON.parse(jsonFormEntity.form);
      // console.log("FULL FORM: ", fullForm);
      const partialForm = JSON.parse(JSON.parse(messageJSON.data)._lctext) || {};
      const mergedForm = _.merge(fullForm, partialForm);
      // console.log("NEW FORM: ", mergedForm);
      let shufuFormChat = new ShufuFormChat({
        removeInstructionMessages: true,
        newMessage: mergedForm,
        language: 'zh',
        createQuestionMessage: createQuestionMessage,
        updateMessageWithAnswer: updateMessageWithAnswer,
        // createAnswerMessage: createAnswerMessage,
        isReplyProcessedSuccess: () => {
          return false;
        }
      });
      console.error('formId-----------------------------', formId);
      setShufuFormChat(shufuFormChat);
      const formStatus = shufuFormChat.getInitialState();
      setMessageShownList(formStatus.messagesShown);
      if (nextForm === 'next') {
        // 跳表动作触发
        // setTimeout(() => {
        setNewFormState(formStatus, formStatus.messagesShown[0]);
        // }, nextFormWaitSecond * 1000);
      } else {
        setFormState(formStatus);
        setPageInfo(formStatus.messagesShown[0]);
        console.error(formStatus.messagesShown[0].text);
        onCallPlayText(formStatus.messagesShown[0].text);
      }
      console.log('formStatus------------------------------', formStatus);
      console.log('pageInfo------------------------------', formStatus.messagesShown[0]);
    } catch (e) {
      // do nothing
      console.log('throw err' + e);
    }
  }, [setNewFormState]);

  const createMessageForPsychSymptom = useCallback((psychSymptoms, messageText, _swipeNext = true) => {
    if (psychSymptomsFormState && !messageText) {
      console.log("NO MESSAGE TEXT!!!!!!!!!!!!!");
      setSwipeNext(_swipeNext);
      setNewFormState(psychSymptomsFormState, psychSymptomsFormState.messagesShown[0]);
    } else {
      const enums = {};
      const enumOrder = [];
      const enumInfo = {};
      psychSymptoms.forEach((symptom, index) => {
        enums[index] = symptom.displayName_zh;
        enumOrder.push(index);
        enumInfo[index] = {
          "match_words": symptom.synonyms_zh || []
        }
      });
      const formStatus = {
        answers: [],
        currentQuestion: "PsychSymptoms/symptom",
        messageObject: {
          "PsychSymptoms/symptom": {
            title_zh: '请选择您的主要症状',
            enum: enums,
            enumOrder,
            enumInfo,
            type: 'array'
          }
        },
        messagesShown: [
          {
            text: '请选择您的主要症状',
            type: "array",
            questionId: "PsychSymptoms/symptom",
            _id: "PsychSymptoms/symptom"
          }
        ],
        model: {},
        questionNumber: 1,
        questionStack: [],
        questionsAnswers: [],
        statestack: {},
        wholeOrder: ["PsychSymptoms/symptom"]
      }
      console.warn('psychSymptoms formStatus===================================================', formStatus);
      setPsychSymptomsFormState(formStatus);
      setSwipeNext(_swipeNext);
      setNewFormState(formStatus, formStatus.messagesShown[0]);
    }
  }, [setNewFormState, psychSymptomsFormState])


  // 获取精中症状选择的选项
  const getPsychSymptoms = useCallback(async (symptomVersion) => {
    if (!psychSymptomList || psychSymptomList.length === 0) {
      const result = await getAllSymptoms(symptomVersion);
      // console.log('getAllSymptoms:', result)
      const symptoms = result.symptoms.filter((symptom) => symptom.tags.includes("chiefComplaint"));
      setPsychSymptomList(symptoms);
      createMessageForPsychSymptom(symptoms, "TEXT", true);
    }

  }, [createMessageForPsychSymptom, psychSymptomList])


  const createMessageForBodyPart = useCallback((symptomList, messageText, _swipeNext = true) => {
    console.log('bodyPartFormState,,,,,,,,,,,,,,,,1', bodyPartFormState);
    if (bodyPartFormState && !messageText) {
      setSwipeNext(_swipeNext);
      setcategoryType('');
      setNewFormState(bodyPartFormState, bodyPartFormState.messagesShown[0]);
    } else {
      const enums = {};
      const enumIcon = {};
      const enumOrder = [];
      symptomList.forEach((chiefComplaint, index) => {
        enums[index] = chiefComplaint.tagName;
        enumIcon[index] = _.get(chiefComplaint, 'icon', '');
        enumOrder.push(index);
      });
      const formStatus = {
        answers: [],
        currentQuestion: "Symptoms/bodyPart",
        messageObject: {
          "Symptoms/bodyPart": {
            title_zh: messageText || '请选择您不舒服的部位',
            enum: enums,
            enumOrder,
            enumIcon,
            type: 'string'
          }
        },
        messagesShown: [
          {
            text: messageText || '请选择您不舒服的部位',
            type: "string",
            questionId: "Symptoms/bodyPart",
            _id: "Symptoms/bodyPart"
          }
        ],
        model: {},
        questionNumber: 1,
        questionStack: [],
        questionsAnswers: [],
        statestack: {},
        wholeOrder: ["Symptoms/bodyPart"]
      }
      //   const [bodyPartFormState, setBodyPartFormState] = useState(null); // bodyPart的formState数据
      // const [chiefComplaintsFormState, setChiefComplaintsFormState] = useState(null); // chiefComplaints的formState数据
      console.warn('-----------------formStatus--formStatus---formStatus------------', symptomList)
      console.log('bodyPartFormState,,,,,,,,,,,,,,,,2', formStatus);
      setBodyPartFormState(formStatus);
      setSwipeNext(_swipeNext);
      setNewFormState(formStatus, formStatus.messagesShown[0]);
    }
  }, [setNewFormState, bodyPartFormState]);

  const getCategoryForm = useCallback(async (formId, partSchema) => {
    console.log('--------------getCategoryForm-----------')
    console.log('--------------***************-----------')
    console.log('--------------*****************-----------')
    if (!symptomList || symptomList.length === 0) {
      try {
        let jsonForm = await getJsonFormById(formId);
        let form = JSON.parse(jsonForm.form);
        let _schema = Object.assign({}, form.schema, partSchema);
        const { category, avSchemaIdSuffix, subcategories, instructions_zh, groupInstructions_zh } = _schema;
        let _symptomList = await getAllComplaintsByTag(
          category,
          avSchemaIdSuffix,
          subcategories
        );
        setSymptomList(_symptomList);
        setChooseChiefComplaintInstructions(instructions_zh);
        createMessageForBodyPart(_symptomList, groupInstructions_zh, true);
        // todo get default recommaned symptoms
      } catch (e) {
        // do nothing
      }
    }
  }, [createMessageForBodyPart, symptomList]);

  const checkForReportUrl = useCallback((_lctext) => {
    if (_lctext.includes('http')) {
      console.log("FOUND URL!", _lctext);
      setCompleteFlag(_lctext.includes('http'));
      // 截取返回信息中的url
      const reg = /(http:\/\/|https:\/\/)((\w|:|\=|\?|\.|\/|&|-|[0-9])+)/g;
      let match;
      while ((match = reg.exec(_lctext)) != null) {
        const url = _lctext.slice(match.index, reg.lastIndex);
        setCompleteURL(url);
      }
      return true;
    } else {
      // if (_lctext.includes('没有推荐的额外的检查项目')) {
      //   setLabTestItems([{id: '123', name: '没有推荐的额外的检查项目', information: '根据您提供的信息，基本的体检可以满足您的需要。'}]);
      //   setTimeout(() => {
      //     setCompleteFlag(true);
      //   }, 0)
      // }
      return false;
    }
  }, []);

  const handleMessageJson = useCallback(messageJson => {
    const updatedMessage = messageJson.message;
    // console.log(updatedMessage['msg-id'], message['msg-id']);
    if (updatedMessage['msg-id'] === message['msg-id']) {
      // console.log("DUPLICATE MESSAGE ID!!!");
      return;
    }
    setMessage(updatedMessage);
    console.log('_messageJSON----------------1', messageJson);
    // const formId = getSessionFormId();
    const _messageData = JSON.parse(_.get(updatedMessage, ['data'], '{}'));
    const _messageLcText = _.get(_messageData, ['_lctext'], '{}');
    const _messageType = _.get(_messageData, ['_lcattrs', 'type']);
    console.log('_messageType----------------1', _messageType);
    if (_messageType === 'text') {
      const hasUrl = checkForReportUrl(_messageLcText);
      if (!hasUrl && _messageLcText !== "您好！|~|Hello!") {
        // console.log("MESSAGE TEXT: ", _messageText);
        setMessageText(_messageLcText);
        setCompleteFlag(true);
      }
      return;
    }
    setCompleteFlag(false);
    setMessageText(null);

    //for jsonSchema messages
    const _messageLcTextJson = JSON.parse(_messageLcText);
    const _shufuFormChatType = _.get(_messageLcTextJson, ['schema', 'shufuFormChatType'], '');
    console.error('_shufuFormChatType-----------------4', _shufuFormChatType);
    const partSchema = _.get(_messageLcTextJson, 'schema', '') || {};
    const shufuFormId = partSchema.shufuFormId;

    if (_shufuFormChatType === 'categorySearch') {
      getCategoryForm(shufuFormId, partSchema);
    } else if (_shufuFormChatType === 'symptomSearch') {
      const symptomVersion = _.get(partSchema, ['symptomVersion'], '');
      getPsychSymptoms(symptomVersion);
    } else if (_shufuFormChatType === 'demoChooseItemsAndViewInvoiceForm') {
      getDemoInvoiceForm(shufuFormId);
    } else {
      getForm(shufuFormId, updatedMessage);
    }

  }, [checkForReportUrl, getCategoryForm, getDemoInvoiceForm, getForm, getPsychSymptoms, message])

  // TODO 获取数据
  useEffect(() => {
    const _messageJSONStr = localStorage.getItem('_messageJSON');

    try {
      console.log("handleMessageJson from useEffect");
      handleMessageJson(JSON.parse(_messageJSONStr));
    } catch (e) {
      console.error("Error handling _messageJSON: ", _messageJSONStr, "errror: ", e);
    }

  }, [handleMessageJson]);

  useEffect(() => {
    async function connectSocket() {
      try {
        await initShuaiCloudFromClientInstance();
      } catch (e) {
        console.error(e);
      }
    }
    connectSocket();
  }, []);

  const createMessageForChiefComplaints = useCallback((index, _swipeNext) => {
    if (chiefComplaintsFormState && !index) {
      setSwipeNext(_swipeNext);
      setNewFormState(chiefComplaintsFormState, chiefComplaintsFormState.messagesShown[0]);
    } else {
      const enums = {};
      const enumOrder = [];
      const enumInfo = {};
      symptomList[index].chiefComplaints.forEach(
        (chiefComplaint, index) => {
          const { synonyms_zh } = chiefComplaint;
          enums[index] = synonyms_zh[0];
          enumOrder.push(index);
          enumInfo[index] = {
            "match_words": synonyms_zh.slice(1)
          };
        });

      const formStatus = {
        answers: [],
        currentQuestion: "Symptoms/symptom",
        messageObject: {
          "Symptoms/symptom": {
            title_zh: chooseChiefComplaintInstructions || '请选择您的主要症状',
            enum: enums,
            enumOrder,
            enumInfo,
            type: 'string'
          }
        },
        messagesShown: [
          {
            text: chooseChiefComplaintInstructions || '请选择您的主要症状',
            type: "string",
            questionId: "Symptoms/symptom",
            _id: "Symptoms/symptom"
          }
        ],
        model: {},
        questionNumber: 1,
        questionStack: [],
        questionsAnswers: [],
        statestack: {},
        wholeOrder: ["Symptoms/symptom"]
      }
      console.warn('formStatus===================================================', formStatus);
      setChiefComplaintsFormState(formStatus);
      setSwipeNext(_swipeNext);
      setNewFormState(formStatus, formStatus.messagesShown[0]);
    }
  }, [chooseChiefComplaintInstructions, setNewFormState, symptomList, chiefComplaintsFormState])

  // 修改答案向前滑动
  const editPreviousAnswerHandler = useCallback(async _categoryType => {
    if (_categoryType === 'Symptoms/bodyPart') {
      setCategoryFormFlag(false);
      createMessageForBodyPart([], '', false);
    } else if (_categoryType === 'Symptoms/symptom') {
      setcategoryType('Symptoms/bodyPart');
      createMessageForChiefComplaints('', false);
    } else {
      console.log('formStatuspppppppppppppp', formState);
      if (categoryFormFlag && formState.messagesShown.length === 1) {
        setcategoryType('Symptoms/symptom');
        createMessageForChiefComplaints('', false);
      } else {
        const newFormState = {
          ...formState,
          ...shufuFormChat.editAnswer(
            formState.messagesShown[1]
          )
        };
        console.log("newFormState: ", newFormState);
        console.log('swipeNext---------', swipeNext);
        setSwipeNext(false);
        setNewFormState(newFormState, newFormState.messagesShown[0]);
      }
    }
    // eslint-disable-next-line no-use-before-define
  }, [categoryFormFlag, createMessageForBodyPart, createMessageForChiefComplaints, formState, setNewFormState, shufuFormChat, swipeNext]);

  const submitFormHandler = useCallback(async (uniqueParams = null) => {
    setDictationText('');
    try {
      await sendActionLogs();
    } catch (e) {
      //alert error
    }
    const { data, from, to, 'msg-id': messageId, timestamp } = message;
    const messageJson = JSON.parse(data || '{}');
    const _form = JSON.parse(messageJson._lctext || '{}');
    const { schema, interaction } = _form;
    // console.log("SUBMIT SCHEMA: ", schema);



    let answerObject = shufuFormChat && shufuFormChat.convertSubmitModel();
    let text = null;
    if ((schema.shufuFormChatType === 'categorySearch')) {
      const model = {
        'symptoms': [
          {
            'id': uniqueParams ? uniqueParams.id : selectedChiefComplaints.id,
            'attributeValueModel': { ...answerObject.model },
            'tags': uniqueParams ? uniqueParams.tags : selectedChiefComplaints.tags
          }
        ]
      };
      answerObject = {
        interaction,
        schema,
        model
      }
    }
    if ((schema.shufuFormChatType === 'symptomSearch')) {
      // console.log("symptomSearch uniqueParams: ", uniqueParams);
      const model = {
        'symptoms': uniqueParams
      }
      answerObject = {
        interaction,
        schema,
        model
      }
    }
    answerObject.interaction.isReply = true;
    text = JSON.stringify(answerObject);

    console.log('message-----111111111111-------');
    console.log(message);
    let body = {
      from,
      to,
      'msg-id': messageId,
      timestamp: new Date(timestamp).getTime(),
      data: {
        _lctext: text,
        _lcattrs: {
          ...messageJson._lcattrs,
          processId: _.get(answerObject, ['interaction', 'processId'], ''), // FIXME workaround here
          businessKey: _.get(answerObject, ['interaction', 'businessKey'], '') // FIXME workaround here
        },
        _lctype: -1 // <-- not sure what's this..., just use -1 by default
      }
    };
    // window.$$f7.dialog.preloader('提交中');
    console.log('body----11111111111111111111--------');
    console.log(body);
    try {
      await replyToForm(body);
      deleteFormStatus(messageId);
    } catch (e) {
      //alert error
    }
    // window.$$f7.dialog.close();

    // console.log({ messageJson, answerObject });
  }, [message, selectedChiefComplaints, shufuFormChat]);






  const createNewFormState = useCallback((index) => {
    console.log('---------selectedBodyPart------------', selectedBodyPart.chiefComplaints[index]);
    const attributeValueSchema = selectedBodyPart.chiefComplaints[index].attributeValueSchema;
    // 症状选择时，如果attributeValueSchema为{}，则提交
    if (attributeValueSchema && JSON.stringify(attributeValueSchema) !== '{}') {

      const formStatus = {
        'schema': {
          type: 'object',
          properties: {
            ...attributeValueSchema
          }
        }
      }
      console.log('----------formStatus------------formStatus-formStatus-------------', formStatus);

      let shufuFormChat = new ShufuFormChat({
        removeInstructionMessages: true,
        newMessage: formStatus,
        language: 'zh',
        createQuestionMessage: createQuestionMessage,
        updateMessageWithAnswer: updateMessageWithAnswer,
        isReplyProcessedSuccess: () => {
          return false;
        }
      });
      console.log('shufuFormChat-----------------------------');
      console.log(shufuFormChat);
      setShufuFormChat(shufuFormChat);
      const _formStatus = shufuFormChat.getInitialState();
      setNewFormState(_formStatus, _formStatus.messagesShown[0]);
    } else if (selectedBodyPart) {
      submitFormHandler(selectedBodyPart.chiefComplaints[index]);
    }

  }, [selectedBodyPart, setNewFormState, submitFormHandler]);

  // 手动清空答案
  function clearAnswerHandler() {
    setDictationText('');
  }

  const answerQuestionHandler = useCallback(
    async answer => {
      console.log('---------answerQuestionHandler-----------' + answer + ", " + formState.currentQuestion);
      if (formState.currentQuestion === "Symptoms/bodyPart") {
        //calculate new enum & enumOrder
        createMessageForChiefComplaints(parseInt(answer), true);
        console.log('selectedBodyPart4444444444444441', symptomList[parseInt(answer)]);
        setSelectedBodyPart(symptomList[parseInt(answer)]);
        setcategoryType('Symptoms/bodyPart');
        setCategoryFormFlag(true);
      } else if (formState.currentQuestion === "Symptoms/symptom") {
        //construct new shufuFormChat
        createNewFormState(parseInt(answer))
        console.log('selectedBodyPart4444444444444442', selectedBodyPart.chiefComplaints[parseInt(answer)]);
        setSelectedChiefComplaints(selectedBodyPart.chiefComplaints[parseInt(answer)]);
        setcategoryType('Symptoms/symptom');
        setCategoryFormFlag(true);
      } else if (formState.currentQuestion === "PsychSymptoms/symptom") {
        submitFormHandler(answer.map(selectedSymptomIndex => psychSymptomList[selectedSymptomIndex]));
        setcategoryType('PsychSymptoms/symptom');
        setCategoryFormFlag(true);
      } else {
        setcategoryType('');
        const newFormState = {
          ...formState,
          ...shufuFormChat.getNewState(
            answer,
            formState.messageObject[formState.currentQuestion],
            formState.messagesShown[0]
          )
        };
        if (!submitFlag) {
          setSubmitFlag(true);
        }
        console.log('swipeNext---------', swipeNext);
        setSwipeNext(true);
        setNewFormState(newFormState, newFormState.messagesShown[0]);
      }
    },
    [createMessageForChiefComplaints, createNewFormState, formState, psychSymptomList, selectedBodyPart.chiefComplaints, setNewFormState, shufuFormChat, submitFlag, submitFormHandler, swipeNext, symptomList]
  );
  // 提交答案
  // function submitAnswer(answer) {
  //   console.error(stayMessage, answer);
  //   setTimeout(() => {
  //     if (stayMessage < message.length - 1) {
  //       const tmpAnswerList = Object.assign([], answerList);
  //       tmpAnswerList.push(answer);
  //       setAnswerList(tmpAnswerList);
  //       let overFlag = true; // 答题完毕
  //       for (let index = stayMessage + 1; index < message.length; index++) {
  //         const message = message[index];
  //         let askedif = _.get(message, 'askedif', false);
  //         if ((_.union(tmpAnswerList, askedif).length < (tmpAnswerList.length + askedif.length)) || !askedif) {
  //           overFlag = false;
  //           setStayMessages(index);
  //           carousel.current.goTo(index, false);
  //           break;
  //         }
  //       }
  //       if (overFlag) {
  //         checkAllAnswerSubmit();
  //       }
  //     } else {
  //       checkAllAnswerSubmit();
  //     }
  //   }, answerWaitSecond * 1000);
  // }

  // 检查是否答题完毕
  function checkAllAnswerSubmit() {
    // if (!allAnswerSubmit) {
    //   setAllAnswerSubmit(true);
    //   alert('答题完毕');
    // }
  }

  // 播报语音
  function onCallPlayText(text) {
    if (window.android) window.android.onJsCallPlayText(text);
  }

  useEffect(() => {
    const existingListeners = messageEventEmitter.listeners('message');
    if (existingListeners.some((listener) => listener.name === 'messageHandler')) return;

    console.log("existingListeners: ", existingListeners);
    const messageHandler = async messageText => {
      const fullMessage = JSON.parse(messageText);
      console.error('fullMessage--------------------------------', fullMessage.message.data);
      console.log('_messageJSON----------------2', fullMessage);
      localStorage.setItem('_messageJSON', JSON.stringify(fullMessage));
      handleMessageJson(fullMessage);
    };

    messageEventEmitter.on('message', messageHandler);
  }, [checkForReportUrl, handleMessageJson]);


  /**
   * 加载组件
   * 
   * case: single-choice => schema.type === 'string' && schema.enum
   * case: multiple-choice => schema.type === 'array'
   * case: number => schema.type === 'number'
   * case: string-input => schema.type === 'string' && !schema.enum
   * 
   */
  const getContent = useCallback((message, messageId, currentQuestion, isCurrent) => {
    const messageType = _.get(message, 'type', '');
    // setCurrentQuestion(currentQuestion);
    if (currentQuestion === 'SUBMIT') {
      if (submitFlag && !completeFlag) {
        setSubmitFlag(false);
        submitFormHandler();
        // setTimeout(() => {
        //   setSubmitFlag(true);
        // }, 5000);
      }

      // 判断是否所有套餐都已完成
      if (completeFlag) {
        if (window.android) window.android.onJSProcessStartOrEnd('end');
        return null;
      } else {
        return (
          <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
            <div className='robot-icon-content'>
              <img
                src="/icons/robot.svg"
                className="robot-icon-instrution"
                alt="logo"
              />
            </div>
            <div style={{
              fontSize: 36,
              marginLeft: 50
            }}>
              <div style={{ marginTop: '20vh' }}>您已完成该部分答题</div>
              <div style={{ marginTop: '5vh' }}>稍后将自动跳转到下一部分...</div>
            </div>
          </div>
        );
      }
    } else if ((messageType === 'string' && message.enum) || (messageType === 'array') || (messageType === 'boolean')) {
      return (
        <div className="carousel-item">
          <MessageBlockSelect
            isCurrent={isCurrent}
            id={messageId}
            key={messageId}
            dictationText={dictationText}
            message={message}
            submitAnswer={answerQuestionHandler}
            editPreviousAnswer={editPreviousAnswerHandler}
            mmessagesShown={formState.messagesShown}
            categoryType={categoryType}
            isFirst={!categoryFormFlag && formState.messagesShown.length === 1}
          ></MessageBlockSelect>
        </div>
      );
    } else if ((messageType === 'number') || (messageType === 'string' && !message.enum)) {
      return (
        <div className="carousel-item">
          <MessageBlockText
            isCurrent={isCurrent}
            id={messageId}
            key={messageId}
            dictationText={dictationText}
            matching={true}
            message={message}
            submitAnswer={answerQuestionHandler}
            clearAnswer={clearAnswerHandler}
            editPreviousAnswer={editPreviousAnswerHandler}
            mmessagesShown={formState.messagesShown}
            categoryType={categoryType}
            isFirst={!categoryFormFlag && formState.messagesShown.length === 1}
          ></MessageBlockText>
        </div>
      );
    } else {
      return null
    }
  }, [answerQuestionHandler, categoryFormFlag, categoryType, completeFlag, dictationText, editPreviousAnswerHandler, formState.messagesShown, submitFlag, submitFormHandler]);

  if (formState.messagesShown.length === 0 && !completeFlag) {
    console.log("NO MESSAGES")
    return null
  }

  function renderItem() {
    // console.log("labTestItems: ", labTestItems);
    return (
      <div>
        <h1 className='labtests-introduction'>为您推荐以下而外的体检项目，你可以自行选择</h1>
        <div>
          {
            labTestItems.map((labTest, index) =>
              <div className='labtest-container' key={labTest.id}>
                <h2 className='labtest-name' >{labTest.name}</h2>
                <p className='labtest-description' >{labTest.information}</p>
              </div>
            )
          }
        </div>
      </div>
    )
  }

  // console.log("completeFlag: ", completeFlag, "completeURL: ", completeURL);
  return (
    <div style={{ overflow: 'hidden' }}>
      <div style={nextFormState
        ? { transform: 'translateX(' + (swipeNext ? '-' : '') + '100%)', transition: 'transform ' + transformSecond + 's' }
        : null
      }>
        {
          messageText ? (
            <div style={{textAlign: 'center'}}>
              <div style={{fontSize:'24px', marginTop: '10%', textAlign: 'center'}}>{messageText}</div>
              <Button type="primary" size='large' style={{ marginTop: '5vh' }} onClick={() => {
                if (window.android) window.android.OnJSReloadPage();
              }} >
                返回首页
              </Button>
            </div>
          ) : null
        }

        {
          completeFlag && labTestItems ? (
            <div>{renderItem()}</div>
          ) : null
        }
        {
          completeFlag && completeURL ? (
            <div>
              {
                openReport ? (
                  <div style={{ textAlign: 'center' }}>
                    <iframe src={completeURL} width='100%' height='600px'></iframe>
                    <Button type="primary" size='large' style={{ marginTop: '5vh' }} onClick={() => {
                      if (window.android) window.android.OnJSReloadPage();
                      else props.history.push({ pathname: '/' });
                    }} >
                      返回首页
                    </Button>
                  </div>
                ) : (
                  <div style={{ textAlign: 'center', fontSize: 40 }}>
                    <div style={{ marginTop: '25vh' }}>您已完成所有答题，谢谢！</div>
                    <Button type="primary" size='large' style={{ marginTop: '5vh' }} onClick={() => setOpenReport(true)} >
                      展开报告
                      </Button>
                  </div>
                )
              }
            </div>
          ) : null}
        {!completeFlag && formState ? (
          <div>
            <div style={{ float: 'left', width: '100%' }}>
              {
                getContent(formState.messageObject[_.get(pageInfo, '_id', 0)], _.get(pageInfo, '_id', 0), formState.currentQuestion, true)
              }
            </div>
            {(nextFormState && nextPageInfo) ? (
              <div style={{ float: 'left', position: 'absolute', left: '100%', width: '100%' }}>
                {
                  getContent(nextFormState.messageObject[_.get(nextPageInfo, '_id', 0)], _.get(nextPageInfo, '_id', 1), nextFormState.currentQuestion, false)
                }
              </div>
            ) : null}
          </div>
        ) : null
        }
      </div>
    </div>
  )
}