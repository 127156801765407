module.exports = {
  SESSION_TOKEN_KEY: 'SESSION_TOKEN_KEY',
  backend: {
    //    shufuLocal: true
    shufuRemote: true
  },
  SHUFU: {
    local: {
      url: 'http://ec2-52-90-94-163.compute-1.amazonaws.com:8088/api/1.2/rest',
      Xurl: 'http://localhost:8088/api/1.2/rest'
    },
    remote: {
      // US SIT server
      // server: 'US',
      // lcPostfix: '.sit',
      // url: 'http://sit.shufu.siuvo.com:8088/api/1.2/rest',
      // hostname: 'http://sit.shufu.siuvo.com:8088',
      // US APROD server
      // server: 'UAT',
      // lcPostfix: '.uat',
      // url: 'http://uat.shufu.siuvo.com:8088/api/1.2/rest',
      // hostname: 'http://uat.shufu.siuvo.com:8088',
      //  Use server in alicloud
      server: 'API_CN',
      lcPostfix: '.api_cn',
      //url: 'http://prod.shufu.siuvo.com:8088/api/1.2/rest',
      // url: 'https://api.siuvo.cn/romeo/api/1.2/rest',
      url: 'https://api.siuvo.cn/romeo/api/1.2/rest',
      // hostname: 'http://prod.shufu.siuvo.com:8088',
      host: 'https://api.siuvo.cn'
    }
  },
  leancloud: {
    // For US Testing
    appId: 'wbBJMPfju0G2dCcunQsaB50E-gzGzoHsz',
    appKey: '6B6MVQYbNExAUSHCNSkB6tJp',
    region: 'cn',
    masterKey: 'YhARN6N5dmewaYBkJju4CUfS',
    bundleId: 'com.siuvo.shufu'

    // For China Testing
    // appId: '5f9nksvvxeac673nkq59l16ntnltvuw500pcgjpthu069ni5',
    // appKey: 'yb92ayxya1w9288037hkt5axdcyfed3q5inxyykyjxba4rte',
    // region: 'cn',

    //US Node
    // appId: 'yO4THTYNrEwUjGxtJacaW1Hp-MdYXbMMI',
    // appKey: 'OOuhPCFEYj773EI1PHdKRxSy',
    // region: 'us',
  }
};
